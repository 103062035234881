.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh; /* Adjust based on your needs */
  }
  
  .logo {
    width: 100%; /* Make the logo responsive */
    max-width: 600px; /* Maximum size */
    height: auto; /* Maintain aspect ratio */
  }
  